import * as React from "react";
import { useState, useEffect, useRef } from "react";

import { _t } from "../i18n";
import { Config, Problem, newProject } from "../api";
import { isStandardException } from "../sdptypes";
import Project from "../Proyecto.json";
import { ElectrumClient, ElectrumTransport } from "electrum-cash";

interface Props {
  activeUserBitcoinCashAddress: null | string;
  activeUserEthereumAddress: null | string;
  activeUserNumber: number | null;
  config: Config;
  problemSet: Array<Problem>;
  sessionId: string;
  setErrorMessage: (s: string) => void;
  setSelectedProblemId: (n: number) => void;
  setShowProjectSubmissionForm: (p: boolean) => void;
  selectedProblemId: number | undefined | null;
}

// when this changes update the sister contract in backend.ts
function contractData(params: {
  acceptanceDeadline: Date;
  fundingDeadline: Date;
  workDeadline: Date;
  verificationDeadline: Date;
  selectedProblemId: number;
  prePay: number;
  postPay: number;
  verifiers: string[];
  stableCoins: string[];
}): string {
  return "";
}

/* For now only users that are providers can submit Projects */
export function SubmitProject(props: Props) {
  const locale = "es-AR";
  const {
    activeUserBitcoinCashAddress,
    activeUserEthereumAddress,
    activeUserNumber,
    config,
    problemSet,
    sessionId,
    selectedProblemId,
    setErrorMessage,
    setSelectedProblemId,
    setShowProjectSubmissionForm,
  } = props;
  const { bitcoin } = config;
  const { currencyBlockchains } = config;
  const [currencyBlockchainIndex, setCurrencyBlockchainIndex] = useState(0);
  const rightNow = new Date().getTime();
  const week = 7 * 24 * 3600 * 1000;
  const [acceptanceDeadline, setAcceptanceDeadline] = useState<Date>(
    new Date(rightNow + 1 * week),
  );
  const [fundingDeadline, setFundingDeadline] = useState<Date>(
    new Date(rightNow + 3 * week),
  );
  const [workDeadline, setWorkDeadline] = useState<Date>(
    new Date(rightNow + 4 * week),
  );
  const [verificationDeadline, setVerificationDeadline] = useState<Date>(
    new Date(rightNow + 7 * week),
  );

  const [postPay, setPostPay] = useState<number>(0);
  const [prePay, setPrePay] = useState<number>(0);
  const problem = problemSet.find((x) => x.id === selectedProblemId);
  const [verifierAddress, setVerifierAddress] = useState(
    "0xa2Ca8979F0A9D2b2Fc39813De7Cd4f9F722b331a",
  );
  const [transactionDetail, setTransactionDetail] = useState("{}");
  const [gas, setGas] = useState(0.0000001);
  const [value, setValue] = useState(0.0001);
  const [gasPrice, setGasPrice] = useState(1e-16);
  const [success, setSuccess] = useState<boolean | null>(null);
  const verifiers = ["0xa2Ca8979F0A9D2b2Fc39813De7Cd4f9F722b331a"];
  const [publicKey, setPublicKey] = useState<null | Buffer>(null);
  const [depositImageURL, setDepositImageURL] = useState(
    "https://cdn.mainnet.cash/wait.svg",
  );
  const [projectId, setProjectId] = useState<null | number>(null);

  const stableCoins = currencyBlockchains
    .filter(
      (c) =>
        c.blockchainId ===
          currencyBlockchains[currencyBlockchainIndex]["blockchainId"] &&
        c.contractAddress,
    )
    .map((c) => {
      console.log(c.contractAddress);
      return "";
    });

  /** curl https://docs-demo.quiknode.pro/ \
  -X POST \
  -H "Content-Type: application/json" \
  --data '{"jsonrpc":"2.0","method":"eth_sendRawTransaction","params":["Signed Transaction"],"id":1}'
  
       params: is the data of a signed tx.
  
       
curl --request POST \
     --url https://eth-mainnet.g.alchemy.com/v2/docs-demo \
     --header 'accept: application/json' \
     --header 'content-type: application/json' \
     --data '
{
  "id": 1,
  "jsonrpc": "2.0",
  "params": [
    "0xf86d808504a817c800825208943535353535353535353535353535353535353535880de0b6b3a7640000801ca0e0d2e3f3d8de8a0c9f25d0b02c7a8a91e7e1c818e6f37a49d8b04f9a9a96a1a0620a6c8d95e0f289bfbf2b3d3e476de6b9bc6d0e974c06f1e4de5be7c5ef0e10"
  ],
  "method": "eth_sendRawTransaction"
}
'


When deploying a contract using the Web3.js library, you will need to include several fields in your transaction object, including "gas", "gasPrice", and "value". In addition to these fields, you will also need to include the contract's bytecode in the "data" field of the transaction object. To include the contract's parameters, you can append them to the end of the contract's bytecode, separated by a comma. For example, if your contract takes two parameters, you can append them to the end of the bytecode like this: "0x,<parameter 1>,<parameter 2>". Once you have constructed your transaction object, you can sign it using your Ethereum private key and send it to the Ethereum network using the "eth_sendRawTransaction" method.
       

{
  "id": 2,
  "jsonrpc": "2.0",
  "method": "account_signTransaction",
  "params": [
    {
      "from": "0x1923f626bb8dc025849e00f99c25fe2b2f7fb0db",
      "gas": "0x55555",
      "maxFeePerGas": "0x1234",
      "maxPriorityFeePerGas": "0x1234",
      "input": "0xabcd",
      "nonce": "0x0",
      "to": "0x07a565b7ed7d7a678680a4c162885bedbb695fe0",
      "value": "0x1234"
    }
  ]
}

constructor(uint96 proposalHash, address prestador, uint96 fondosAnterior, uint96 fondosPosterior, uint96 fetchaLimiteDeAcepcia, uint96 fetchaLimiteDeFundos, uint96 fetchaLimiteDeTrabajo, uint96 fetchaLimiteDeVerificacion, address[] memory verificadores, IERC20[] memory stableCoinsAcceptados)


       **/

  useEffect(() => {
    try {
      const base = 1e18;
      const tx = {
        gasPrice: Math.floor(base * gasPrice).toString(16),
        value: Math.floor(base * value).toString(16),
        gas: Math.floor(base * gas).toString(16),
        data: "",
      };

      if (
        selectedProblemId &&
        (activeUserBitcoinCashAddress ||
          (activeUserEthereumAddress !== null &&
            activeUserEthereumAddress !== "")) &&
        fundingDeadline &&
        workDeadline
      ) {
        if (activeUserEthereumAddress !== "") {
          const acceptanceDeadlineHex =
            "0x" + (new Date().getTime() / 1000 + 2 * 3600).toString(16);
          const fundingDeadlineHex =
            "0x" + (fundingDeadline.getTime() / 1000).toString(16);

          const workDeadlineNumber = workDeadline.getTime() / 1000;
          const verificationDeadlineNumber =
            workDeadlineNumber + 2 * 7 * 24 * 3600;

          const workDeadlineHex = "0x" + workDeadlineNumber.toString(16);
          const verificationDeadlineHex =
            "0x" + verificationDeadlineNumber.toString(16);

          const data =
            "0x," +
            selectedProblemId.toString(16) +
            "," +
            (activeUserEthereumAddress
              ? activeUserEthereumAddress.slice(2)
              : "") +
            "," +
            (prePay * base).toString(16) +
            "," +
            (postPay * base).toString(16) +
            "," +
            acceptanceDeadlineHex.slice(2) +
            "," +
            fundingDeadlineHex.slice(2) +
            "," +
            workDeadlineHex.slice(2) +
            "," +
            verificationDeadlineHex.slice(2) +
            "," +
            verifiers.slice(2) +
            "," +
            stableCoins;
          setTransactionDetail(JSON.stringify(tx, null, "    "));
          console.log(data);
          tx.data =
            Project.bytecode +
            contractData({
              acceptanceDeadline,
              fundingDeadline,
              workDeadline,
              verificationDeadline,
              selectedProblemId,
              prePay,
              postPay,
              verifiers,
              stableCoins,
            });
        } else if (activeUserBitcoinCashAddress) {
          // do nothing
        }
      } else {
        throw Error(
          "selectedProblemId or both activeUserBitcoinCashAddress and activeUserEthereumAddress are falsy",
        );
      }
    } catch (e) {
      console.log(
        {
          selectedProblemId,
          activeUserEthereumAddress,
          fundingDeadline,
          workDeadline,
        },
        e,
      );
      setTransactionDetail("{}");
    }
  }, [
    gasPrice,
    value,
    gas,
    selectedProblemId,
    activeUserEthereumAddress,
    fundingDeadline,
    workDeadline,
    prePay,
    postPay,
    verifiers,
    stableCoins,
  ]);

  try {
    const { currencyId, blockchainId } =
      currencyBlockchains[currencyBlockchainIndex];
    const currency = currencyId;

    const handleChangedProblem = (ev) => {
      const value = ev.target.value;
      const problem = problemSet.find((p) => p.title === value);
      if (problem === undefined) {
        return;
      }
      setSelectedProblemId(problem.id);
    };

    // const deployContract = () => {};

    const handleSubmit = () => {
      if (selectedProblemId && fundingDeadline && workDeadline)
        newProject(
          sessionId,
          activeUserNumber ?? 0,
          selectedProblemId,
          currency,
          blockchainId,
          prePay,
          postPay,
          fundingDeadline,
          workDeadline,
          ".",
          gasPrice,
          value,
          transactionDetail,
        )
          .then((jSON) => {
            console.log(jSON);
            const { success, errorMessage } = jSON;
            setSuccess(success);
            if (success) {
              const { projectId } = jSON;
              setProjectId(projectId);
            } else {
              setErrorMessage(errorMessage);
            }
          })
          .catch((e) => {
            console.error(e);
            if (isStandardException(e)) {
              setErrorMessage(_t("problem.unable-to-submit-proposal"));
            } // if
          });
    };

    /**     
    In here, the user should be prompted to send himself some bitcoincash to the address he has recorded here.  This gives us a means of getting the publicKey which we can later use to create smart contracts on a per-deposit basis for donors who will be able to get most of their money back if the contract is not fully funded or the provider can be fully paid, all the while not having to trust us to keep the coins for either of them.
    */

    const notBitcoin =
      currencyId !== "BCH" && currencyId !== "tBCH" && currencyId !== "rBCH";
    const selectedCurrencyId =
      currencyBlockchains[currencyBlockchainIndex].currencyId;
    const selectedCurrencyDecimalPlaces =
      currencyBlockchains[currencyBlockchainIndex].decimalPlaces;
    const [formatedPrepay, setFormatedPrePay] = useState("");
    const [formatedPostPay, setFormatedPostPay] = useState("");

    const handleBlurred = (ev) => {
      console.log(currencyBlockchains[currencyBlockchainIndex]);
      if (ev.target.value === "") {
        return;
      }
      const tokens = ev.target.value.split(/ /);
      const nV = parseFloat(tokens[0]);
      let buffer = nV.toFixed(selectedCurrencyDecimalPlaces);
      let lastZero = buffer.length;
      let i = buffer.length;
      for (; i >= 1 && buffer.charAt(i - 1) === "0"; --i);
      if (i >= 2 && buffer.charAt(i - 1) === ".") {
        --i;
      }
      buffer = buffer.slice(0, i);
      ev.target.value = buffer + " " + selectedCurrencyId;
    };

    const handlePrePayChanged = (ev) => {
      setFormatedPrePay(ev.target.value);
      const tokens = ev.target.value.split(/ /);
      const nV = parseFloat(tokens[0]);
      setPrePay(nV);

      setFormatedPrePay(
        nV.toFixed(selectedCurrencyDecimalPlaces) + " " + selectedCurrencyId,
      );
    };

    const handlePostPayChanged = (ev) => {
      setFormatedPrePay(ev.target.value);
      const tokens = ev.target.value.split(/ /);
      const nV = parseFloat(tokens[0]);
      setPostPay(nV);
      setFormatedPostPay(
        nV.toFixed(selectedCurrencyDecimalPlaces) + " " + selectedCurrencyId,
      );
    };

    if (projectId) {
      return (
        <div>
          :) :) :) :) :) :) :) :) :) :) :) :) :) :) :) :) :) :)
          {_t("project.congratulations", {
            projectId,
            acceptanceDeadline: acceptanceDeadline.toLocaleDateString(locale),
          })}
          <br />
          {_t("problem.acceptance-deadline")}:{" "}
          {acceptanceDeadline.toLocaleDateString(locale)} <br />
        </div>
      );
    }

    return (
      <div id="project-submission-form" className="horizontallyArranged">
        {JSON.stringify(projectId)}
        <div id="project-submission-fields">
          {problemSet && (
            <div className="form-line">
              <label>{_t("problem.problem")}</label>
              {problemSet.length < 15 ? (
                <select
                  name="problem-select"
                  onChange={(ev) =>
                    setSelectedProblemId(parseInt(ev.target.value))
                  }
                  defaultValue={
                    selectedProblemId === null ? undefined : selectedProblemId
                  }
                >
                  {problemSet.map((problem) => (
                    <option
                      key={problem.id}
                      value={problem.id}
                      onChange={console.log}
                    >
                      {problem.title}
                    </option>
                  ))}
                </select>
              ) : (
                <>
                  <input
                    type="text"
                    className=""
                    list="problems"
                    onChange={handleChangedProblem}
                    defaultValue={problem?.title}
                  />
                  <datalist id="problems">
                    {problemSet.map((problem) => (
                      <option key={problem.id} value={problem.title}>
                        {problem.title}
                      </option>
                    ))}
                  </datalist>
                </>
              )}
            </div>
          )}

          <div className="form-line">
            {_t("problem.currency-blockchain")}:
            <select
              defaultValue={currencyBlockchainIndex}
              onChange={(ev) =>
                setCurrencyBlockchainIndex(parseInt(ev.target.value))
              }
            >
              {currencyBlockchains.map((cb, i) => {
                return (
                  <option key={i} value={i} onSelect={console.log}>
                    {`${cb.currencyName}/${cb.blockchainDisplayName}`}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-line">
            {_t("problem.amount-to-charge-before-work")}:
            <input
              defaultValue={formatedPrepay}
              type="text"
              placeholder={selectedCurrencyId}
              pattern={"d+(.d{1,8})?( " + selectedCurrencyId + ")?"}
              onChange={handlePrePayChanged}
              onBlur={handleBlurred}
            />
          </div>

          <div className="form-line">
            {_t("problem.amount-to-charge-after-work")}:
            <input
              type="text"
              pattern="\d+(\.\d{1,8})?"
              onChange={handlePostPayChanged}
              onBlur={handleBlurred}
            />
          </div>

          <div className="project-element">
            <div className="form-line">
              {_t("problem.acceptance-deadline")}:
              <input
                type="date"
                onChange={(ev) =>
                  setAcceptanceDeadline(new Date(ev.target.value))
                }
              />
            </div>
            <div className="odd-dark">
              {_t("problem.acceptance-deadline-detail")}
            </div>
          </div>

          <div className="project-element">
            <div className="form-line">
              {_t("problem.funding-deadline")}:
              <input
                type="date"
                onChange={(ev) => setFundingDeadline(new Date(ev.target.value))}
              />
            </div>
            <div className="even-dark">
              {_t("problem.funding-deadline-detail")}
            </div>
          </div>

          <div className="project-element">
            <div className="form-line">
              {_t("problem.work-deadline")}:
              <input
                type="date"
                onChange={(ev) => setWorkDeadline(new Date(ev.target.value))}
              />
            </div>
            <div className="odd-dark">{_t("problem.work-deadline-detail")}</div>
          </div>

          <input
            type="hidden"
            onChange={(ev) => setVerifierAddress(ev.target.value)}
            defaultValue={verifierAddress}
          />

          {notBitcoin && (
            <div className="form-line">
              {_t("problem.gas")}
              <input
                type="text"
                onChange={(ev) => setGas(parseFloat(ev.target.value))}
                defaultValue={gas}
              />
            </div>
          )}

          {notBitcoin && (
            <div className="form-line">
              {_t("problem.value")}
              <input
                type="text"
                onChange={(ev) => setValue(parseFloat(ev.target.value))}
                defaultValue={value}
              />
            </div>
          )}

          {notBitcoin && (
            <div className="form-line">
              {_t("problem.gasPrice")}
              <input
                type="text"
                onChange={(ev) => setGasPrice(parseFloat(ev.target.value))}
                defaultValue={gasPrice}
              />
            </div>
          )}

          <div>
            <label>{_t("project.send-to-self")}</label>
            <input
              className="bitcoincash-address"
              placeholder={
                notBitcoin
                  ? _t("project.ethereum-address")
                  : _t("project.bitcoincash-address")
              }
              maxLength={124}
              defaultValue={
                (notBitcoin
                  ? activeUserEthereumAddress
                  : activeUserBitcoinCashAddress) ?? ""
              }
              onChange={console.log}
            />
            <p style={{ textAlign: "center" }}>
              <img src={depositImageURL} style={{ width: "15em" }} />
            </p>
          </div>
          <div>
            {selectedProblemId && (
              <button onClick={handleSubmit}>{_t("problem.submit")}</button>
            )}
            <button onClick={() => setShowProjectSubmissionForm(false)}>
              {_t("g.close")}
            </button>
          </div>
        </div>

        <div id="project-submission-debug">
          <pre>{transactionDetail}</pre>
          {success === true ? (
            "successful"
          ) : success === false ? (
            "failure"
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  } catch (e) {
    console.error(e);
    console.log({ currencyBlockchains, currencyBlockchainIndex });
    return <div>...</div>;
  }
}
