import * as React from "react";
import { useState, useCallback, useEffect, useRef } from "react";
import L from "leaflet";
import { shrinkPhoto } from "../api";
// @gus-include problem.pot-hole
// @gus-include problem.damaged sign
// @gus-include problem.missing sign

import { Buffer } from "buffer";

import {
  Comodoro_Rivadavia,
  decimalToSexigesimal,
  focusZoom,
  isBoundingBoxStringQuad,
  isPlace,
  maxZoom,
  parseBoundingBoxStringQuad,
  Place,
  wideZoom,
  zoom_city,
  zoom_neighborhood,
} from "../osm";

import * as ls from "../local-storage";

import {
  Config,
  Problem,
  searchProblems,
  getProblemImages,
  OptionalProblem,
  searchNeighborhoods,
  SearchNeighborhoodResponse,
} from "../api";
import { isNumber, isStandardException, sleep, ViewType } from "../sdptypes";
import { SubmitProject } from "./submitproject";
import { ErrorBoundary } from "./errorboundary";
import { _t } from "../i18n";

type Timeout = ReturnType<typeof setTimeout>;
type LeafLetType = any;
const defaultZoom = 13;

interface ProblemMapProps {
  title: string;
  place: Place;
  cityDecimalLatitude: string;
  cityDecimalLongitude: string;
  problemSet: Array<Problem>;
  mapId?: string;
  selectedProblemId?: number | null;
  sessionId: string;
  setProblemSet: (ps: Array<Problem>) => void;
  setSelectedProblemId?: (id: number) => void;
  setZoom: (z: number) => void;
  scrollToProblem?: (id: number) => void;
  style?: { [id: string]: string | number };
  zoom: null | number;
}

export function ProblemMap(props: ProblemMapProps) {
  const { place, problemSet, mapId, setZoom, zoom } = props;
  const { selectedProblemId, sessionId } = props;
  const [controlId, setControlId] = useState<undefined | string>(mapId);
  const [settingMap, setSettingMap] = useState<boolean>(false);
  const [mapIsSet, setMapIsSet] = useState<boolean>(false);
  const [boundingBox, setBoundingBox] = useState<
    null | { lat: number; lng: number; alt: number }[]
  >(null);
  const [centerCoordiantes, setCenterCoordinates] = useState<null | string[]>(
    null,
  );
  // normally zooms are done in the map and the map control controls the
  // zooom value (save the initial setup).  Here set zoomChangeCount to
  // an incrementing function to force the map to rerender
  const [decLocation, setDecLocation] = useState<null | {
    lat: number;
    lng: number;
  }>(null);
  // select suburb, count(id) from problem_table group by suburb;
  const [neighborhoodMarkers, setNeighborhoodMarkers] =
    useState<null | SearchNeighborhoodResponse>(null);

  const style = props.style;
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    // Set the id in the map div.
    // Because I want this called only once, the routine renders
    // once and then this is called, setting the controlId.

    // console.log("setting id");
    console.log("controlId is " + controlId);

    if (controlId && controlId > "")
      return () => {
        // Without this, it would try to undo itself and then generate a new string for an id -- adnauseum.
        // return early.  this is a redundant call.
      };

    let newId = "";
    for (let i = 0; i < 12; ++i) {
      newId += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[Math.floor(Math.random() * 26)];
    }
    setControlId(newId);

    return () => {
      // There is nothing really to be gained setting this to null here.
      // A new random string is not going to be better than the old one.
      // console.error("calling the id destructor now.");
    };
  }, []);

  useEffect(() => {
    // Run after the second render, for it needs to have the map rendered
    // with the id assigned in an earlier effect.  This only happens once
    // though.
    // console.log("Running the L.map() constructor");

    if (!controlId) return () => {};

    if (mapRef.current || settingMap || mapIsSet) {
      return () => {};
    }

    setSettingMap(true);
    let mapObj = L.map(controlId, {
      zoomControl: false,
      attributionControl: false,
    });
    mapRef.current = mapObj;
    setSettingMap(false);
    setMapIsSet(true);
  }, [controlId]);

  const mapObj = mapRef.current;

  useEffect(() => {
    if (!mapObj) return () => {};

    const zoomendHandler = (ev: L.ZoomAnimEvent) => {
      console.log("capturing zoom end", ev);
      const { sourceTarget } = ev;
      if (sourceTarget && (sourceTarget._zoom || sourceTarget._zoom === 0)) {
        const newZoom = sourceTarget._zoom;
        console.log("Zoom detected.  Setting zoom to:", newZoom);
        setZoom(newZoom);
        if (newZoom <= zoom_neighborhood && neighborhoodMarkers == null) {
          console.log(place);
          try {
            searchNeighborhoods(/*cityName*/ place.name, sessionId).then(
              (nd) => {
                for (const suburb in nd) {
                  const ndd: {
                    count: number;
                    location: L.LatLng;
                    marker: any;
                  } = nd[suburb];
                  const { location, count } = ndd;
                  const numIcon = L.divIcon({
                    className: "my-div-icon",
                    html: `<div class='my-div-icon'>${count}</div>`,
                  });
                  ndd.marker = L.marker(location, {
                    title: suburb,
                    icon: numIcon,
                    riseOnHover: true,
                  });
                }
                setNeighborhoodMarkers(nd);
              },
            );
          } catch (e) {
            console.log(e);
          }
        } // if
      } // if
    };
    mapObj.on("zoomend", zoomendHandler);
    return () => mapObj.off("zoomend", zoomendHandler);
  }, [mapObj]);

  useEffect(() => {
    if (!mapObj) {
      return () => {};
    }
    setCenterCoordinates([
      place.lat, // N or S
      place.lon, // E or W
    ]);
    mapObj.setView(
      [
        place.lat, // N or S
        place.lon, // E or W
      ],
      zoom === null ? defaultZoom : zoom,
    );
  }, [mapObj]);

  useEffect(() => {
    if (mapObj) {
      const tileLayer = L.tileLayer(
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom,
        },
      ).addTo(mapObj);
      return () => {
        tileLayer.removeFrom(mapObj);
      };
    } else {
      return () => {};
    }
  }, [mapIsSet, mapObj]);

  useEffect(() => {
    if (mapObj && mapObj.attributionControl?.setPrefix)
      mapObj.attributionControl.setPrefix("Leaflet & OpenStreetMap");
  }, [mapObj]);

  const map = (mapObj as L.Map) || null;

  // @renderInMapEffect: Will update marker settings on every change to
  // problemSet.
  useEffect(() => {
    // If the problemSet changes, this effect runs the placeMarkers routine.
    // If already run once, it will run the placeMarker destructor
    // prior to doing that.
    try {
      const placeMarkers = (mapObj) => {
        if (!controlId || !mapIsSet || !zoom) return () => {};

        if (zoom <= zoom_neighborhood && neighborhoodMarkers != null) {
          const markers: L.Marker[] = [];
          if (!mapObj || !neighborhoodMarkers) return () => {};
          for (const suburb in neighborhoodMarkers) {
            const this_neighborhood = neighborhoodMarkers[suburb];
            if (this_neighborhood.marker != null) {
              mapObj.addLayer(this_neighborhood.marker);
              markers.push(this_neighborhood.marker);
            }
          }
          return () => {
            for (const marker of markers) {
              marker.removeFrom(mapObj);
            }
          };
        } else {
          for (const problem of problemSet) {
            const { marker, image, streetName, streetNumber, crossStreetName } =
              problem;
            const title = streetName
              ? streetNumber
                ? _t("problem.number-street-address", {
                    streetNumber,
                    streetName,
                  })
                : crossStreetName
                  ? _t("problem.cross-street-address", {
                      crossStreetName,
                      streetName,
                    })
                  : streetName
              : "";

            if (image && marker) {
              try {
                const i = document.createElement("img");
                i.onload = () => {
                  const s = document.createElement("span");
                  const d = document.createElement("div");
                  i.className = "listImage";
                  s.innerText = title;

                  d.appendChild(s);
                  // if (i.naturalHeight === 1) {
                  //   // Image too small
                  // }
                  i.className = "popup";
                  d.className = "verticallyArranged";
                  d.appendChild(i);
                  marker.bindTooltip(d);
                };
                i.src = image;
                marker.on("dragend", console.log);
              } catch (e) {
                console.error(e, { image, problem });
              }
            }

            if (marker) {
              mapObj.addLayer(marker);
            }
          } // for
        }

        return () => {
          // console.log("place marker destructor.  Remove markers here?");
          const newProblemSet = [...problemSet];
          for (const problem of newProblemSet) {
            const marker = problem.marker;
            if (marker) {
              marker.off("dragend", console.log);
              marker.removeFrom(mapObj);
            }
          }
        };
      };
      if (mapObj) return placeMarkers(mapObj);
    } catch (e) {}
    console.log("zoom=", zoom);

    return () => {};
  }, [mapIsSet, mapObj, problemSet, zoom]);

  let decLongitude: null | number = null;
  let decLatitude: null | number = null;
  const problem = problemSet.find((p) => p.id === selectedProblemId);
  useEffect(() => {
    const dtor = () => {};
    if (!map) {
      return dtor;
    }

    if (!problem) {
      document.title = props.title;
      return dtor;
    }

    const { location, marker } = problem;
    const { decLatitude: latitude, decLongitude: longitude } = location;
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error("latitude and longitude wrong:", problem);
      return dtor;
    }
    setDecLocation({ lat: latitude, lng: longitude });
    map.setView([latitude, longitude], zoom ?? focusZoom);
    if (!marker) return dtor;
    //console.log("Marker present...");
    marker.openTooltip([latitude, longitude]);
    return () => {
      marker.closeTooltip();
    };
  }, [mapIsSet, mapObj, problem]);

  return (
    controlId && (
      <div>
        <div style={style} id={controlId} className="problemMap">
          <div
            id="zoom-in-all-button"
            className={
              zoom === focusZoom
                ? "map-button-control zoom-disabled"
                : "map-button-control zoom-enabled"
            }
            onClick={(ev) =>
              decLocation && zoom
                ? mapObj.setView(decLocation, maxZoom)
                : mapObj.setZoom(maxZoom)
            }
          >
            <div className="centered">++</div>
          </div>
          <div
            id="zoom-in-once-button"
            className={
              zoom && zoom < maxZoom
                ? "map-button-control zoom-enabled"
                : "map-button-control zoom-disabled"
            }
            onClick={(ev) => mapObj.setZoom((zoom ?? focusZoom) + 1)}
          >
            <div className="centered">+</div>
          </div>
          <div
            id="zoom-out-once-button"
            className={
              zoom
                ? "map-button-control zoom-enabled"
                : "map-button-control zoom-disabled"
            }
            onClick={(ev) => mapObj.setZoom((zoom ?? focusZoom) - 1)}
          >
            <div className="centered">-</div>
          </div>
        </div>
      </div>
    )
  );
}
