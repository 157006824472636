import * as React from "react";
import { useState, useCallback, useEffect, useRef } from "react";
import L, { DragEndEvent } from "leaflet";
import ExifReader from "exif-js";
import { PageProps } from "./pagetypes";
import {
  decimalToSexigesimal,
  sexigesimalToDecimal,
  OSMEvent,
  Place,
  isBoundingBoxStringQuad,
  parseBoundingBoxStringQuad,
  Comodoro_Rivadavia,
  wideZoom,
  focusZoom,
  maxZoom,
  getStreetsAround,
  IdNamePair,
  GetStreetsAroundSuccessfulReturnType,
  Way,
  reverse,
  zoom_city,
} from "../osm";
import * as ls from "../local-storage";
import { isString, isStandardException, ViewType, sleep } from "../sdptypes";
import { searchProblems, Problem } from "../api";
import { _t } from "../i18n";
import { shrinkPhoto } from "../api";

// interface GeoCoord {
//   lat: number;
//   lon: number;
// }

// I don't know where to find these
type Timeout = ReturnType<typeof setTimeout>;
type LeafLetType = any;

enum StepValue {
  Start = 0,
  LocationFromPhoto,
  AddressEntry,
  MapPlacement = 9,
}

// function findIntersections(
//   pointsA,
//   pointsB,
// ): Array<{ lat: number; lon: number }> {
//   const solution_set: Array<{ lat: number; lon: number }> = [];
//   const linesA: Array<{ m: number; b: number }> = pointsToLines(pointsA);
//   const linesB: Array<{ m: number; b: number }> = pointsToLines(pointsB);
//   for (const lineA of linesA) {
//     for (const lineB of linesB) {
//       const intersection_lon = (lineB.b - lineA.b) / (lineA.m - lineB.m);
//       const intersection_lat = lineA.m * intersection_lon + lineA.b;
//       solution_set.push({
//         lat: intersection_lat,
//         lon: intersection_lon,
//       });
//     }
//   }
//   return solution_set;
// }

// // find where these lines intersect here.
// function pointsToLines(
//   points: Array<NumericPlace>,
// ): Array<{ m: number; b: number }> {
//   let prev: null | NumericPlace = null;
//   const nullsAndLines: Array<{ m; b } | null> = points.map((v, i) => {
//     if (prev == null) {
//       prev = v;
//       return null;
//     }
//     // slope well... bearing actually
//     const m = (v.lat - prev.lat) / (v.lon - prev.lon);
//
//     // y-intercept well ... meridian intercept actually
//     const b = v.lat - m * v.lon;
//     return { m, b };
//   });
//   // @ts-ignore
//   const newPoints2: Array<{ m; b }> = nullsAndLines.filter(
//     (nol) => nol !== null,
//   );
//   return newPoints2;
// }

const halfSquareSide = 13; /*m*/
const cameraResolutionSide = 40; /*m*/

interface SubmitProblemProps extends PageProps {
  appendProblem: (
    problemId: number,
    problemType: string,
    streetNumber: number | null,
    streetName: string,
    crossStreetName: string | null,
    description: string,
    latitude: number,
    longitude: number,
    marker: L.Marker,
    image: string | null,
  ) => void;
  cities: { [id: string]: Place };
  close: () => void;
  currentCity: string;
  defaultImage: string;
  defaultPhotoEncoding: string;
  language: string;
  oSMEnabled: boolean;
  problemListRef: { current: HTMLDivElement | null };
  problemSet: Problem[];
  problemTableHeaderRef: { current: any };
  problemTableBodyRef: { current: any };
  problemTableRef: { current: any };
  sessionId: string;
  setErrorMessage: (errorMessage: string) => void;
  setOSMEnabled: (boolean) => void;
  setView;
  streetOrder: "big-endian" | "little-endian";
  view: ViewType;
}

export function SubmitProblem(props: SubmitProblemProps) {
  const {
    appendProblem,
    close,
    cities,
    currentCity,
    defaultImage,
    defaultPhotoEncoding,
    language,
    oSMEnabled,
    problemListRef,
    problemTableHeaderRef,
    problemTableBodyRef,
    problemTableRef,
    problemSet,
    sessionId,
    setErrorMessage,
    streetOrder,
    setView,
    view,
  } = props;

  const EW = `E${_t("problem.west-letter")}`;
  const NS = "NS";
  const [problemType, setProblemType] = useState<string>("");

  const setNewProblemType = (ev) => {
    setProblemType(ev.target.value);
  };

  const [streetNumber, setStreetNumber] = useState<string>("");
  const [streetName, setStreetName] = useState<string>(
    ls.get("street-name") ?? "",
  );
  const [crossStreet, setCrossStreet] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [resizingPhoto, setResizingPhoto] = useState(false);
  const [photo, setPhoto] = useState(defaultImage);
  const [photoFormat, setPhotoFormat] = useState(defaultPhotoEncoding);
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  // For Marking Steps
  const [addressEntryStepChosen, setAddressEntryStepChosen] = useState(false);
  const [placedOnMapStepChosen, setPlacedOnMapStepChosen] = useState(false);
  const [
    coordinatesSetFromDeviceStepChosen,
    setCoordinatesSetFromDeviceStepChosen,
  ] = useState(false);
  const [takePhotoStepChosen, setTakePhotoStepChosen] = useState(false);

  // grabbing these from local storage ruins things:  The locally stored one sticks inspite of setting them to
  // other values.  We don't need it anyway but this problem may be reflected in the city setting below when we move
  // to doing this to other cities.
  const [latitude, __setLatitude] = useState<string>("");
  const validateStringArgument = useCallback(
    (fnName: string, argName: string, argument: any) => {
      if (typeof argument !== "string")
        throw new Error(
          `Error bad input: ${fnName}()'s ${argName} argument is not a string`,
        );
    },
    [],
  );
  const setLatitude = useCallback(
    (coordinate: string) => {
      validateStringArgument("setLatitude", "coordinate", coordinate);
      __setLatitude(coordinate);
    },
    [__setLatitude, validateStringArgument],
  );
  const [longitude, __setLongitude] = useState<string>("");
  const setLongitude = useCallback(
    (coordinate: string) => {
      validateStringArgument("setLongitude", "coordinate", coordinate);
      __setLongitude(coordinate);
    },
    [validateStringArgument, __setLongitude],
  );

  const [city, onlySetCity] = useState(currentCity);
  // S, N, W, E
  //const cityBounds = [-45.91, -45.766, -67.66, -67.37];
  const [addressUpdateTimeoutHandle, setAddressUpdateTimeoutHandle] =
    useState<Timeout | null>(null);

  const [firstStreetPath, setFirstStreetPath] = useState<Array<Place>>([]);
  const [crossStreetPath, setCrossStreetPath] = useState([]);
  const [showAllPrevs, __setShowAllPrevs] = useState(false);
  const [showAllNexts, __setShowAllNexts] = useState(false);
  const [step, __setStep] = useState<StepValue>(StepValue.Start);
  const setStep = (step: StepValue) => {
    __setShowAllPrevs(false);
    __setShowAllNexts(false);
    __setStep(step);
  };
  const setShowAllPrevs = (b) => {
    __setShowAllPrevs(b);
    if (b) {
      __setShowAllNexts(false);
    }
  };
  const setShowAllNexts = (b) => {
    __setShowAllNexts(b);
    if (b) {
      __setShowAllPrevs(false);
    }
  };

  const [cityError, setCityError] = useState("");
  const [streetNumberError, setStreetNumberError] = useState("");
  const [streetNameError, setStreetNameError] = useState("");
  const [crossStreetError, setCrossStreetError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  // these four use decimal coordinates (numeric)
  const [latitudeOfDevice, setLatitudeOfDevice] = useState<null | number>(null);
  const [longitudeOfDevice, setLongitudeOfDevice] = useState<null | number>(
    null,
  );
  // const [latitudeOfPhoto, setLatitudeOfPhoto] = useState<null | number>(null);
  // const [longitudeOfPhoto, setLongitudeOfPhoto] = useState<null | number>(null);

  const [submittingProblem, setSubmittingProblem] = useState<boolean>(false);
  // const [photoSize, setPhotoSize] = useState([0, 0]);
  const [queryingStreets, setQueryingStreets] = useState(false);
  const [possibleStreets, setPossibleStreets] = useState<Array<Way>>([]);
  const [gettingCoordinatesFromDevice, setGettingCoordinatesFromDevice] =
    useState(false);
  const [getStreetsAroundLastCall, setGetStreetsAroundLastCall] = useState(
    new Date().getTime(),
  );
  const [hasZoomed, setHasZoomed] = useState<boolean>(false);
  const setGetStreetsAroundNewCall = () =>
    setGetStreetsAroundLastCall(new Date().getTime());
  const [squareSideLength, setSquareSideLength] = useState(17 /*m*/);
  const mapId = document.getElementById("map");

  const capturedImageRef = useRef<null | HTMLImageElement>(null);
  const mapRef = useRef<LeafLetType | null>(null);
  const newMarkerRef = useRef<LeafLetType | null>(null);
  let title: string;
  if (problemType === "") {
    title = "";
  } else if (crossStreet > "") {
    title =
      "problems." +
      problemType +
      " - " +
      _t("problem.cross-street-address", {
        streetName,
        crossStreetName: crossStreet,
      });
  } else if (streetNumber) {
    title =
      "problems." +
      problemType +
      " - " +
      _t("problem.number-street-address", {
        streetName,
        streetNumber: streetNumber,
      });
  } else {
    title = _t("problems." + problemType) + " - " + streetName;
  }

  const iconRef = useRef<{ [id: string]: L.Icon }>({});
  const cameraInputRef = useRef<HTMLInputElement>(null);
  const cityInfo = cities[city];
  const handleStreetsAround = (jSON) => {
    console.log("getStreetsAround:", jSON);
    if (typeof jSON !== "number") {
      const { ways, relations } = jSON;
      setPossibleStreets(ways);
      if (ways.length === 2) {
        setStreetName(ways[0].tags.name);
        setCrossStreet(ways[1].tags.name);
      } else if (ways.length === 1) {
        setStreetName(ways[0].tags.name);
      } else if (ways.length === 0) {
      }
      if (relations.length > 0) {
        setCity(relations[0].tags.name);
      }
    } else {
      console.log(`error getStreetsAround: ${jSON}`);
    }
    setQueryingStreets(false);
  };
  const getProblemsAround: (
    lat: number,
    lng: number,
    squareSize: number,
  ) => Promise<Problem[]> = (lat, lng, squareSize) => {
    setSquareSideLength(squareSize);
    const halfSquare = squareSize / 2;
    const latDelta = (halfSquare * 9) / 1e6;
    const lngDelta = (halfSquare * 9) / 1e6 / Math.cos((lat * Math.PI) / 180);
    const maxNorth = lat + latDelta;
    const minNorth = lat - latDelta;
    const minEast = lng - lngDelta;
    const maxEast = lng + lngDelta;
    const proximityFilter = (problem) =>
      minNorth < problem.location.decLatitude &&
      problem.location.decLatitude < maxNorth &&
      minEast < problem.location.decLongitude &&
      problem.location.decLongitude < maxEast;
    if (problemSet.length)
      return new Promise((resolve, reject) => {
        resolve(problemSet.filter(proximityFilter));
      });
    else
      return new Promise((resolve, reject) => {
        searchProblems("any", currentCity, language, sessionId).then(
          (foundProblems) =>
            resolve(foundProblems.filter(proximityFilter) ?? []),
        );
      });
  };

  useEffect(() => {
    if (queryingStreets) return;
    if (!latitude || !longitude) {
      return;
    }
    if (new Date().getTime() - getStreetsAroundLastCall <= 20_000) {
      return;
    }
    setQueryingStreets(true);
    try {
      setGetStreetsAroundNewCall();
      getStreetsAround(latitude, longitude, NS, EW).then(handleStreetsAround);
    } catch (e) {
      console.error(e);
      setQueryingStreets(false);
    }
  }, [EW, NS, latitude, longitude]);

  function getGeoCoordinates(): GeoDecimalCoordinatesPromise {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          },
        );
      } else {
        // @ts-ignore
        reject(new Error(_t("problem.geolocation-not-supported")));
      }
    });
  }

  useEffect(() => {
    const potHoleIcon = L.icon({
      iconUrl: "./pot-hole.png",
      iconSize: [70, 42],
      iconAnchor: [35, 21],
      popupAnchor: [0, -21],
    });

    const brokenSignIcon = L.icon({
      iconUrl: "./broken-sign.png",
      iconSize: [70, 42],
      iconAnchor: [35, 41],
      popupAnchor: [0, -21],
    });

    const missingSignIcon = L.icon({
      iconUrl: "./missing-sign.png",
      iconSize: [70, 42],
      iconAnchor: [35, 41],
      popupAnchor: [0, -21],
    });

    iconRef.current["pot hole"] = potHoleIcon;
    iconRef.current["damaged sign"] = brokenSignIcon;
    iconRef.current["missing sign"] = missingSignIcon;

    if ("geolocation" in navigator) {
      getGeoCoordinates()
        .then((coordinates) => {
          const { latitude, longitude } = coordinates;
          console.log(latitude, longitude);
          setLatitudeOfDevice(latitude);
          setLongitudeOfDevice(longitude);
          if (!latitude || !longitude) {
            setErrorMessage(_t("problem.no-GPS-information-from-device"));
          } else {
            setLatitude(decimalToSexigesimal(latitude, NS));
            setLongitude(decimalToSexigesimal(longitude, EW));
            if (hasZoomed) {
              mapRef.current.setView(
                [latitude, longitude],
                mapRef.current.getZoom(),
              );
            } else {
              setHasZoomed(true);
              mapRef.current.setView([latitude, longitude], maxZoom);
            }
          }
        })
        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    console.log(latitudeOfDevice, longitudeOfDevice);
    if (latitudeOfDevice !== null)
      setLatitude(decimalToSexigesimal(latitudeOfDevice, NS));
    if (longitudeOfDevice !== null)
      setLongitude(decimalToSexigesimal(longitudeOfDevice, NS));
  }, [latitudeOfDevice, longitudeOfDevice]);

  const setCity = useCallback(
    (cityName: string) => {
      const cityRecord = cities[cityName]; // Comodoro_Rivadavia
      if (cityRecord) {
        const lat = decimalToSexigesimal(parseFloat(cityRecord.lat), NS);
        const lng = decimalToSexigesimal(parseFloat(cityRecord.lon), EW);
        setLatitude(lat);
        setLongitude(lng);
        ls.set("city", city);
        ls.set("latitude", lat);
        ls.set("longitude", lng);
      }
      onlySetCity(cityName);
    },
    [onlySetCity, EW, cities, city, setLatitude, setLongitude],
  );

  //const [debugText, setDebugText] = useState("");
  const debugText = "";

  const scrollToLastProblem = () => {
    const problemListElement = document.getElementById("problemList");
    const problemTableElement = document.getElementById("problemTable");
    const problemTableHeaderElement =
      document.getElementById("problemTableHeader");
    const problemTableBodyElement = document.getElementById("problemTableBody");

    if (
      !problemListRef ||
      !problemListRef.current ||
      !problemTableRef.current ||
      !problemTableHeaderRef.current ||
      !problemTableBodyRef.current ||
      !problemTableBodyRef.current.firstChild
    ) {
      // no ref backup code.

      if (
        !problemListElement ||
        !problemTableElement ||
        !problemTableBodyElement ||
        !problemTableHeaderElement
      ) {
        // try again later...
        setTimeout(scrollToLastProblem, 500);
        return;
      }
      // @ts-ignore outdated ts doesn't know of clientHeight
      let scrollAmount = problemTableHeaderElement.clientHeight;
      for (
        let tableRowElement = problemTableBodyElement.firstChild;
        !!tableRowElement;
        tableRowElement = tableRowElement.nextSibling
      ) {
        if (!tableRowElement) break;
        // @ts-ignore outdated ts doesn't know of clientHeight
        scrollAmount += tableRowElement.clientHeight;
      }
      problemListElement.scrollTo({
        top: scrollAmount,
        behavior: "smooth",
      });
      return;
    }
    let scrollAmount = problemTableHeaderRef.current.clientHeight;
    for (
      let tableRowElement = problemTableBodyRef?.current?.firstChild;
      !!tableRowElement;
      tableRowElement = tableRowElement.nextSibling
    ) {
      if (!tableRowElement) break;
      scrollAmount += tableRowElement.clientHeight;
    }
    problemListRef.current.scrollTo({
      top: scrollAmount,
      behavior: "smooth",
    });
  };

  // Asumption: newMarkerRef.current is a valid new marker.
  const sendToServer = async (ev) => {
    try {
      ev.preventDefault();
      if (newMarkerRef.current === null) {
        console.error("newMarkerRef.current == null");
        setErrorMessage("backend.error");
        return;
      }
      console.log("submittnig to server...");
      setSubmittingProblem(true);
      // we send location as latitude, longitude in decimal form.
      const location: number[] = [
        sexigesimalToDecimal(latitude, NS),
        sexigesimalToDecimal(longitude, EW),
      ];

      console.log(`Photo length: ` + photo.length);
      let body = photoFormat + "," + photo;

      // shouldn't happen but it does...
      if (photo.length > 500000) {
        // still too big.
        setResizingPhoto(true);
        // SuperVGA should be big enough and small enough...
        const imageStruct = await shrinkPhoto(photoFormat + "," + photo, 800);
        body = imageStruct.url;
        setResizingPhoto(false);
      }

      const headers: Array<[string, string]> = [
        ["language", language],
        ["title", title],
        ["problem-type", problemType],
        ["location", location.map((x) => x + "").join(", ")],
        ["streetName", streetName],
        ["crossStreetName", crossStreet],
        ["body", description],
        ["session_id", sessionId],
      ];

      if (!isNaN(parseInt(streetNumber)))
        headers.push(["streetNumber", `${streetNumber}`]);

      try {
        const newProblemResponse: Response = await fetch(
          "/private-api/newProblem",
          {
            method: "POST",
            headers: headers,
            body,
          },
        );
        if (newProblemResponse.status !== 200) {
          console.log(latitude, longitude);
          console.log(headers);
          setErrorMessage(
            `The server says: "${newProblemResponse.statusText}"`,
          );
          return;
        }

        const newProjectResponseJSON = await newProblemResponse.json();
        const { errorMessage, problemId } = newProjectResponseJSON;

        // XXX newMarkerRef.current not null?
        if (newMarkerRef.current === null) {
          setErrorMessage(_t("problem.problem"));
          return;
        }

        if (!errorMessage) {
          appendProblem(
            problemId,
            problemType,
            streetNumber === "" ? null : parseInt(streetNumber),
            crossStreet,
            streetName,
            description,
            location[0],
            location[1],
            newMarkerRef.current,
            photoFormat + "," + photo,
          );
          // marker now part of problemSet, dereference here.
          newMarkerRef.current = null;
          setStep(StepValue.Start);
          close();

          // try to scroll to the end of the list of problems at most {chances} times.

          setTimeout(scrollToLastProblem, 2500);
        } else {
          console.log(errorMessage);
          setErrorMessage(errorMessage);
          console.log({ headers });
        }
      } catch (e) {
        // this may help in figuring out what happened.
        console.log({ headers });
        throw e;
      }
    } catch (e) {
      if (isStandardException(e)) {
        setErrorMessage(e.message);
        console.log(e);
      } else console.error(e);
      setSubmittingProblem(false);
    }
  };

  const handleOSMEvent = (oSMEvent: OSMEvent) => {
    try {
      const { type } = oSMEvent;
      if (type === "drag" || type === "dragend") {
        return;
      }
      if (oSMEvent.latlng === undefined) {
        console.error(oSMEvent);
        return;
      }
      const decLatitude = oSMEvent.latlng.lat;
      const decLongitude = oSMEvent.latlng.lng;

      if (oSMEvent.type === "click") {
        if (new Date().getTime() - getStreetsAroundLastCall > 20_000) {
          setQueryingStreets(true);
          setGetStreetsAroundNewCall();
          getStreetsAround(decLatitude, decLongitude, NS, EW)
            .then(handleStreetsAround)
            .catch(() => setQueryingStreets(false));
        }

        const sexLatitude = decimalToSexigesimal(decLatitude, NS);
        const sexLongitude = decimalToSexigesimal(decLongitude, EW);
        ls.set("latitude", sexLatitude);
        ls.set("longitude", sexLongitude);
        setLongitude(sexLongitude);
        setLatitude(sexLatitude);

        //mapRef.current.setView([oSMEvent.latlng.lat, oSMEvent.latlng.lng]);
        try {
          setStreetsFromDecLatLng(decLatitude, decLongitude);
        } catch (e) {
          console.error(e);
        }
        if (newMarkerRef.current !== null) {
          // remove handlers.
          newMarkerRef.current.off();
          newMarkerRef.current.remove();
          newMarkerRef.current = null;
        }
        const icon = iconRef.current[problemType];
        const marker = L.marker(
          { lat: decLatitude, lng: decLongitude },
          { draggable: true, autoPan: true, icon },
        );
        marker.on("dragend", updateLL);
        mapRef.current.addLayer(marker);
        newMarkerRef.current = marker;
      } else {
        newMarkerRef.current.setLatLng(decLatitude, decLongitude);
      }
    } catch (e) {
      console.log(e);
      if (isStandardException(e)) {
        setErrorMessage(e.message);
      }
    }
  };

  // @ts-ignore
  const updateLL = (e: DragEndEvent) => {
    if (newMarkerRef.current === null) return;
    const latlng = newMarkerRef.current.getLatLng();
    const sexLat = decimalToSexigesimal(latlng.lat, NS);
    const sexLng = decimalToSexigesimal(latlng.lng, EW);

    setLatitude(sexLat);
    setLongitude(sexLng);

    // don't bother the server too much.
    if (new Date().getTime() - getStreetsAroundLastCall <= 20_000) {
      return;
    }

    setQueryingStreets(true);
    setGetStreetsAroundNewCall();
    getProblemsAround(latlng.lat, latlng.lng, 2 * halfSquareSide /*m*/).then(
      (existingProblems) => {
        if (existingProblems.length > 0) {
          setErrorMessage(_t("problem.there-already-is-a-problem-here"));
          clearFormData();
          setView("projects");
          return;
        }
        getStreetsAround(latlng.lat, latlng.lng, NS, EW)
          .then(handleStreetsAround)
          .catch(() => setQueryingStreets(false));
      },
    );
  };

  const clearAddressWarnings = () => {
    setCityError("");
    setStreetNameError("");
    setStreetNumberError("");
    setCrossStreetError("");
    setDescriptionError("");
  };

  const handleAddressBlur = async (
    p_streetNumber,
    p_streetName,
    p_crossStreet,
    p_city,
    p_description,
  ) => {
    if (p_city === "") {
      setCityError(_t("problem.field-cannot-be-blank"));
    }
    if (p_streetName === "") {
      setStreetNameError(_t("problem.field-cannot-be-blank"));
    }
  };

  const handleCityChange = (ev) => {
    clearAddressWarnings();
    setCity(ev.target.value);
    if (addressUpdateTimeoutHandle) {
      clearTimeout(addressUpdateTimeoutHandle);
    }
    setAddressUpdateTimeoutHandle(
      setTimeout(
        handleAddressBlur,
        2000,
        streetNumber,
        streetName,
        crossStreet,
        ev.target.value,
        description,
      ),
    );
  };

  const handleStreetNumberChange = (ev) => {
    ev.preventDefault();
    clearAddressWarnings();
    setStreetNumber(ev.target.value.toString());
    if (addressUpdateTimeoutHandle) {
      clearTimeout(addressUpdateTimeoutHandle);
    }
    setAddressUpdateTimeoutHandle(
      setTimeout(
        handleAddressBlur,
        2000,
        ev.target.value.toString(),
        streetName,
        crossStreet,
        city,
        description,
      ),
    );
  };

  const handleCrossStreetChange = (ev) => {
    ev.preventDefault();
    clearAddressWarnings();
    setCrossStreet(ev.target.value);
    if (addressUpdateTimeoutHandle) {
      clearTimeout(addressUpdateTimeoutHandle);
    }
    setAddressUpdateTimeoutHandle(
      setTimeout(
        handleAddressBlur,
        2000,
        streetNumber,
        streetName,
        ev.target.value,
        city,
        description,
      ),
    );
  };

  const handleStreetNameChange = (ev) => {
    clearAddressWarnings();
    setStreetName(ev.target.value);
    if (addressUpdateTimeoutHandle) {
      clearTimeout(addressUpdateTimeoutHandle);
    }
    setAddressUpdateTimeoutHandle(
      setTimeout(
        handleAddressBlur,
        2000,
        streetNumber,
        ev.target.value,
        crossStreet,
        city,
        description,
      ),
    );
  };

  const handleDescriptionChange = (ev) => {
    clearAddressWarnings();
    setDescription(ev.target.value);
    if (addressUpdateTimeoutHandle) {
      clearTimeout(addressUpdateTimeoutHandle);
    }
    setAddressUpdateTimeoutHandle(
      setTimeout(
        handleAddressBlur,
        2000,
        streetNumber,
        ev.target.value,
        crossStreet,
        city,
        ev.target.value,
      ),
    );
  };

  const clearFormData = () => {
    setLatitude("");
    setLongitude("");
    setStreetName("");
    setCrossStreet("");
    setStreetNumber("");
    setDescription("");
    setCrossStreetPath([]);
    setFirstStreetPath([]);
    setStep(StepValue.Start);
    setAddressEntryStepChosen(false);
    setPlacedOnMapStepChosen(false);
    setCoordinatesSetFromDeviceStepChosen(false);
    setTakePhotoStepChosen(false);
  };

  const maybeInitializeMarker = (decLat: number, decLng: number) => {
    getProblemsAround(decLat, decLng, 2 * halfSquareSide).then(
      (existingProblems) => {
        if (existingProblems.length > 0) {
          setErrorMessage(_t("problem.there-already-is-a-problem-here"));
          clearFormData();
          setView("projects");
          return;
        }
        try {
          if (oSMEnabled && mapId && mapRef.current) {
            setStreetsFromDecLatLng(decLat, decLng);
            mapRef.current.setView([decLat, decLng], focusZoom);
            if (newMarkerRef.current === null) {
              const marker = L.marker(
                { lat: decLat, lng: decLng },
                { draggable: true, autoPan: true },
              );
              marker.on("dragend", updateLL);
              mapRef.current.addLayer(marker);
              newMarkerRef.current = marker;
            } else {
              newMarkerRef.current.setLatLng({ lat: decLat, lng: decLng });
            }
          } else {
            console.log("Not initializing the marker because ", {
              oSMEnabled,
              mapId,
              mapRefcurrent: mapRef.current,
            });
          }
        } catch (e) {
          if (newMarkerRef.current) {
            newMarkerRef.current.off();
            newMarkerRef.current.remove();
            newMarkerRef.current = null;
          }
        }
      },
    );
  };

  const initializeMap = () => {
    // we check here but initialize mapRef.current later.  Why?
    if (!mapId || mapRef.current) {
      return () => {};
    }
    try {
      console.log("setting up positioning map");
      mapRef.current = L.map("map");
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(mapRef.current);
      try {
        if (newMarkerRef.current) {
          newMarkerRef.current.addTo(mapRef.current);
        }
        if (!cityInfo) {
          throw new Error("City Info not set");
        }
        if (!mapRef.current) {
          throw new Error("mapRef not set");
        }
        const boundingBox: string[] = cityInfo.boundingbox;
        if (isBoundingBoxStringQuad(boundingBox)) {
          const parsedBoundingBox = parseBoundingBoxStringQuad(boundingBox);
          mapRef.current.fitBounds(parsedBoundingBox);
        } else {
          console.error("bad data:", boundingBox);
          throw new Error("bad data");
        }
      } catch (e) {
        mapRef.current.setView(
          [Comodoro_Rivadavia.lat, Comodoro_Rivadavia.lon],
          focusZoom,
        );
        console.error("using setView because :", e);
      }
      mapRef.current.on("click dragend drag", handleOSMEvent);
    } catch (e) {
      console.log(e);
      if (mapRef.current === null) {
        return () => {};
      }
    }
    return () => {
      console.log("removing positioning map");
      if (mapRef?.current?.remove) mapRef.current.remove();
      mapRef.current = null;
    };
  };

  useEffect(clearFormData, [view, setLatitude, setLongitude]);

  useEffect(initializeMap, [mapId, mapRef.current, step]);

  useEffect(() => {
    if (!mapId || step !== StepValue.MapPlacement || !mapRef.current) {
      return () => {};
    }
    if (!mapRef.current) {
      return () => {};
    }
    const map = mapRef.current;

    try {
      const decLat = sexigesimalToDecimal(latitude, NS);
      const decLng = sexigesimalToDecimal(longitude, EW);
      // code here assumes the Earth is a perfect sphere
      // There is no correction for tides or rotation bulge.
      const brownSquare = L.polyline(
        [
          {
            lat: decLat - (squareSideLength * 9) / 2e6,
            lng:
              decLng -
              (squareSideLength * 9) / 2e6 / Math.cos((Math.PI / 180) * decLat),
          },
          {
            lat: decLat - (squareSideLength * 9) / 2e6,
            lng:
              decLng +
              (squareSideLength * 9) / 2e6 / Math.cos((Math.PI / 180) * decLat),
          },
          {
            lat: decLat + (squareSideLength * 9) / 2e6,
            lng:
              decLng +
              (squareSideLength * 9) / 2e6 / Math.cos((Math.PI / 180) * decLat),
          },
          {
            lat: decLat + (squareSideLength * 9) / 2e6,
            lng:
              decLng -
              (squareSideLength * 9) / 2e6 / Math.cos((Math.PI / 180) * decLat),
          },
          {
            lat: decLat - (squareSideLength * 9) / 2e6,
            lng:
              decLng -
              (squareSideLength * 9) / 2e6 / Math.cos((Math.PI / 180) * decLat),
          },
        ],
        { color: "brown" },
      );
      brownSquare.addTo(map);
      maybeInitializeMarker(
        sexigesimalToDecimal(latitude, NS),
        sexigesimalToDecimal(longitude, EW),
      );
      return () => {
        brownSquare.removeFrom(map);
      };
    } catch (e) {
      console.error(e);
    }
  }, [mapId, mapRef.current, step, latitude, longitude, squareSideLength]);

  const handlePhotoInputChange = (event) => {
    try {
      event.preventDefault();
      console.log("photo input has been changed");
      setLoadingPhoto(true);
      const fileInput = event.target;
      const file = fileInput.files[0];

      if (!file) {
        console.error(_t("problem.no-file-selected"));
        setLoadingPhoto(false);
        return;
      }

      const base64Reader = new FileReader();

      base64Reader.onloadend = async () => {
        const imageFileContents = base64Reader.result;
        if (isString(imageFileContents)) {
          console.log("Photo loaded");
          setResizingPhoto(true);

          shrinkPhoto(imageFileContents, 800)
            .then((imageStruct) => {
              const newPhoto = imageStruct.url;
              setResizingPhoto(false);

              console.log("Photo shrunk");
              const imagePair = newPhoto.split(",");
              console.log(imagePair[0]);
              const imageFormat = imagePair[0];
              const imageData = imagePair[1];

              setPhotoFormat(imageFormat);
              setPhoto(imageData);

              if (capturedImageRef.current) {
                capturedImageRef.current.src = imageFormat + "," + imageData;
                capturedImageRef.current.style.display = "block";
              }

              ExifReader.getData(file, function () {
                let decLat: number | null = latitudeOfDevice;
                let decLng: number | null = longitudeOfDevice;
                try {
                  const allMetaData = ExifReader.getAllTags(this);
                  console.log(allMetaData);
                  const {
                    GPSLatitude,
                    GPSLatitudeRef,
                    GPSLongitude,
                    GPSLongitudeRef,
                  } = allMetaData;
                  // Camera data will use English language bearing letters.
                  decLat =
                    (GPSLatitudeRef === "N" ? 1 : -1) *
                    (GPSLatitude[0] +
                      GPSLatitude[1] / 60 +
                      GPSLatitude[2] / 3600);
                  decLng =
                    (GPSLongitudeRef === "E" ? 1 : -1) *
                    (GPSLongitude[0] +
                      GPSLongitude[1] / 60 +
                      GPSLongitude[2] / 3600);
                  const sexLat = `${GPSLatitude[0]}º${GPSLatitude[1]}'${
                    GPSLatitude[2]
                  }"${GPSLatitudeRef === "N" ? NS[0] : NS[1]}`;
                  const sexLng = `${GPSLongitude[0]}º${GPSLongitude[1]}'${
                    GPSLongitude[2]
                  }"${GPSLongitudeRef === "E" ? EW[0] : EW[1]}`;
                  console.log({
                    problemSetLength: problemSet.length,
                    sexLat,
                    sexLng,
                  });

                  console.log(
                    "getProblemsAround:",
                    decLat,
                    decLng,
                    cameraResolutionSide,
                  );
                  getProblemsAround(decLat, decLng, cameraResolutionSide).then(
                    (problems) => {
                      setLatitude(sexLat);
                      setLongitude(sexLng);
                      // setLatitudeOfPhoto(decLat);
                      // setLongitudeOfPhoto(decLng);
                      setQueryingStreets(true);
                      setGetStreetsAroundNewCall();
                      console.log(
                        problems,
                        `decLat: ${decLat}, decLng: ${decLng}`,
                      );
                      if (problems.length) {
                        setErrorMessage(
                          _t("problem.there-already-is-a-problem-here"),
                        );
                        clearFormData();
                        setView("projects");
                        return;
                      }
                      if (decLat && decLng)
                        getStreetsAround(decLat, decLng, "NS", "EW")
                          .then(handleStreetsAround)
                          .catch(() => setQueryingStreets(false));

                      setStreetsFromDecLatLng(
                        (GPSLatitudeRef === "N" ? 1 : -1) *
                          (GPSLatitude[0] +
                            GPSLatitude[1] / 60 +
                            GPSLatitude[2] / 3600),
                        (GPSLongitudeRef === "E" ? 1 : -1) *
                          (GPSLongitude[0] +
                            GPSLongitude[1] / 60 +
                            GPSLongitude[2] / 3600),
                      );
                    },
                  );
                } catch (e) {
                  if (decLat === null || decLng === null) {
                    console.log(
                      "failing in getting GPS information from photo",
                      e,
                    );
                    setErrorMessage(
                      _t("problem.no-GPS-information-in-the-photo"),
                    );
                  } // else that's okay, we can use the device coordinates.
                } finally {
                  if (
                    typeof decLat === "number" &&
                    typeof decLng === "number"
                  ) {
                    setLatitude(decimalToSexigesimal(decLat, NS));
                    setLongitude(decimalToSexigesimal(decLng, EW));
                  }
                  setLoadingPhoto(false);
                }
              });
            })
            .catch(() => setResizingPhoto(false));
        } else {
          console.log("Error loading file");
        }
      };
      base64Reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error handling photo input change:", error);
      setLoadingPhoto(false);
    }
  };

  interface GeoDecimalCoordinates {
    latitude: number;
    longitude: number;
  }

  type GeoDecimalCoordinatesPromise = Promise<GeoDecimalCoordinates>;
  // const clearPhoto = (ev) => {
  //   ev.preventDefault();
  //   // Set the default image when you want to clear the existing photo
  //   setPhoto(defaultImage);
  //
  //   if (capturedImageRef.current) {
  //     capturedImageRef.current.src = "data:image/jpeg;base64," + defaultImage;
  //     capturedImageRef.current.style.display = "none";
  //   }
  // };

  function setCoordinatesFromDeviceLocation(): Promise<GeoDecimalCoordinates | null> {
    setGettingCoordinatesFromDevice(true);
    const sleepPromise = sleep(2000 /*ms*/);
    const getCoordinatesPromise = getGeoCoordinates();
    return Promise.all([sleepPromise, getCoordinatesPromise])
      .then((allOutcomes) => {
        // far too imprecise in some circumstances.
        const coordinates = allOutcomes[1];
        return getProblemsAround(
          coordinates.latitude,
          coordinates.longitude,
          2 * halfSquareSide,
        ).then(async (problems) => {
          if (problems.length) {
            setErrorMessage(_t("problem.there-already-is-a-problem-here"));
            clearFormData();
            setView("projects");
            return null;
          } else if (
            parseFloat(Comodoro_Rivadavia.boundingbox[0]) >
              coordinates.latitude ||
            parseFloat(Comodoro_Rivadavia.boundingbox[1]) <
              coordinates.latitude ||
            parseFloat(Comodoro_Rivadavia.boundingbox[2]) >
              coordinates.longitude ||
            parseFloat(Comodoro_Rivadavia.boundingbox[3]) <
              coordinates.longitude
          ) {
            setErrorMessage(
              _t("problem.other-cities-not-supported", ["Comodoro only"]),
            );
            return null;
          } else {
            const response = await reverse(
              /*coordinates.latitude,
              coordinates.longitude,*/ -45.8,
              -67.5,
              zoom_city,
              1,
            );
            try {
              if (!response) throw Error("response from reverse is falsy");
              if (!response.length)
                throw new Error("response from reverse has no length");
              if (response.length > 1) {
                console.log(response);
              }
              console.log(response[0]);
              const { address } = response[0];
              if (address) {
                const { city, country } = address;
                if (country != "Argentina" || city != "Comodoro Rivadavia") {
                  console.log({ city, country });
                  throw new Error("city and country doesn't match");
                }
              }
            } catch (e) {
              if (isStandardException(e)) console.log(e.message);
              if (isStandardException(e))
                setErrorMessage(
                  _t("problem.other-cities-not-supported") + e.message,
                );
            }
          }
          setStreetsFromDecLatLng(coordinates.latitude, coordinates.longitude);
          setLatitude(decimalToSexigesimal(coordinates.latitude, NS));
          setLongitude(decimalToSexigesimal(coordinates.longitude, EW));
          return coordinates;
        });
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
        return null;
      })
      .finally(() => {
        setCoordinatesSetFromDeviceStepChosen(true);
        setGettingCoordinatesFromDevice(false);
        return null;
      });
  }

  const setStreetsFromDecLatLng = (
    decLat: undefined | number = undefined,
    decLng: undefined | number = undefined,
  ) => {
    if (new Date().getTime() - getStreetsAroundLastCall <= 20_000) {
      return;
    }
    if (streetName !== "") return;
    if (decLat === undefined && latitude > "") {
      decLat = sexigesimalToDecimal(latitude, NS);
    }
    if (
      decLng === undefined &&
      longitude > "" &&
      typeof decLat === "number" &&
      !isNaN(decLat)
    ) {
      decLng = sexigesimalToDecimal(longitude, EW);
    }
    if (decLng === undefined || decLat === undefined) {
      return;
    }
    if (decLng === null || decLat === null) {
      throw new Error(_t("problem.decLng or decLat is null"));
    }
    if (isNaN(decLng) || isNaN(decLat)) {
      throw new Error(_t("problem.decLng or decLat isn't a number"));
    }

    setQueryingStreets(true);
    setGetStreetsAroundNewCall();
    return getStreetsAround(decLat, decLng, NS, EW)
      .then((places: GetStreetsAroundSuccessfulReturnType | number) => {
        console.log("getStreets:", places);
        if (typeof places === "number") {
          console.log("setStreetsFromDecLatLng: getStreetsAround:", places);
        } else if (places.ways.length === 0) {
          console.log("setStreetsFromDecLatLng: getStreetsAround:", {
            crossStreets: places.ways,
          });
          console.log("setStreetsFromDecLatLng: getStreetsAround:", "failed.");
        } else {
          setPossibleStreets(places.ways);
          if (places.relations.length && places.relations[0].tags?.name) {
            setCity(places.relations[0].tags.name);
          }
          if (places.ways.length === 2) {
            console.log(
              "setStreetsFromDecLatLng: getStreetsAround:",
              `got ${places.ways.length} streets.  The first is ${places.ways[0].tags.name}.`,
            );
            setStreetName(places.ways[0].tags.name);
            console.log(
              "setStreetsFromDecLatLng: getStreetsAround:",
              `The other is ${places.ways[1].tags.name}.`,
            );
            setCrossStreet(places.ways[1].tags.name);
          }
        }
      })
      .catch((e) => {
        console.error("setStreetsFromDecLatLng: getStreetsAround:", e);
        setErrorMessage(e.message);
        setQueryingStreets(false);
      });
  };

  const hasPreciseLocation = latitude !== "" && longitude !== "";
  const hasRoughAddress = city !== "" && streetName !== "";
  const doesntHavePreciseLocation = !hasPreciseLocation;
  const doesntHaveRoughAddress = !hasRoughAddress;

  const StartOverLink = (props: { step: StepValue }) => {
    if (step !== StepValue.Start)
      return (
        <div
          className="submition-option-group"
          onClick={() => {
            setStep(StepValue.Start);
            setTakePhotoStepChosen(false);
            setAddressEntryStepChosen(false);
            setPlacedOnMapStepChosen(false);
            setTakePhotoStepChosen(false);
          }}
        >
          <div className="submition-icon-container">
            <i className="fa-fa-arrow-left submition-option-icon" />
          </div>
          <div>
            <span className="submition-option-text">
              {_t("problem.change-type-of-problem")}
            </span>
          </div>
        </div>
      );
    return <div />;
  };

  const setStepToLocationFromPhoto = (ev) => {
    setStep(StepValue.LocationFromPhoto);
    setLoadingPhoto(true);
    setTakePhotoStepChosen(true);
    cameraInputRef.current?.click();
  };

  const GetLocationFromPhotoLink = (props: {
    className: string;
    step: StepValue;
  }) => {
    if (props.step !== StepValue.LocationFromPhoto)
      return (
        <div
          className="submition-option-group"
          onClick={setStepToLocationFromPhoto}
        >
          <div className="submition-icon-container">
            <i className="fa fa-camera submition-option-icon" />
          </div>
          <div>
            <span className="submition-option-text">
              {_t("problem.get-location-from-a-photo")}
            </span>
          </div>
        </div>
      );
    return <></>;
  };

  const SetCoordinatesFromDeviceLink = (props: { step: StepValue }) => {
    if (props.step !== StepValue.LocationFromPhoto)
      return (
        <div
          className="submition-option-group"
          onClick={(ev) => setCoordinatesFromDeviceLocation().then(console.log)}
        >
          <div className="submition-icon-container">
            <i
              className={
                gettingCoordinatesFromDevice
                  ? "blink-me fa fa-search fa-phone submition-option-icon"
                  : "fa fa-search fa-phone submition-option-icon"
              }
            />
          </div>
          <div>
            <span className="submition-option-text">
              {_t("problem.set-coordinates-from-device")}
            </span>
          </div>
        </div>
      );
    return <></>;
  };

  const PlaceOnMapLink = (props: { step: StepValue }) => {
    return (
      <div
        className="submition-option-group"
        onClick={() => {
          setStep(StepValue.MapPlacement);
          setPlacedOnMapStepChosen(true);
        }}
      >
        <div className="submition-icon-container">
          <i className="fa fa-map submition-option-icon" />
        </div>
        <div>
          <span className="submition-option-text">
            {_t("problem.choose-location-on-a-map")}
          </span>
        </div>
      </div>
    );
  };

  const AddressEntryLink = (props: { step: StepValue }) => {
    if (props.step !== StepValue.AddressEntry)
      return (
        <div
          className="submition-option-group"
          onClick={() => {
            setAddressEntryStepChosen(true);
            setStep(StepValue.AddressEntry);
          }}
        >
          <div className="submition-icon-container">
            <i className="fa fa-pencil submition-option-icon" />
          </div>
          <div>
            <span className="submition-option-text">
              {_t("problem.type-address-in")}
            </span>
          </div>
        </div>
      );
    return <></>;
  };

  const PrevBackLink = (props: {}) => {
    return (
      <div className="prev-step">
        <div className="vertically-centered">
          <div
            className="fa fa-arrow-left submition-option-icon"
            onClick={() => setShowAllPrevs(true)}
          />
        </div>
      </div>
    );
  };

  const NextForwardLink = (props: {}) => {
    return (
      <div className="next-step">
        <div className="vertically-centered">
          <div
            className="fa fa-arrow-right submition-option-icon"
            onClick={() => setShowAllNexts(true)}
          />
        </div>
      </div>
    );
  };

  const UploadLink = (props: {}) => {
    return submittingProblem === false ? (
      <div
        className="submition-option-group"
        onClick={(ev) => sendToServer(ev)}
      >
        <div className="submition-icon-container">
          <i className="fa fa-arrow-up submition-option-icon" />
        </div>
        <div>
          <span className="submition-option-text">
            {_t("problem.upload-entry")}
          </span>
        </div>
      </div>
    ) : resizingPhoto ? (
      _t("problem.resizing-photo")
    ) : (
      _t("problem.submitting")
    );
  };

  const prev = (step: StepValue) => {
    if (problemType === "") return <div />;
    if (showAllPrevs)
      return (
        <div className="prev-step">
          <div
            className="fa fa-times submition-option-icon"
            onClick={() => setShowAllPrevs(false)}
          />
          <StartOverLink step={step} />
          {takePhotoStepChosen && hasPreciseLocation && (
            <GetLocationFromPhotoLink
              className="fa fa-arrow-left wallbreathe"
              step={step}
            />
          )}
          {hasPreciseLocation && coordinatesSetFromDeviceStepChosen && (
            <SetCoordinatesFromDeviceLink step={step} />
          )}
          {newMarkerRef.current !== null && placedOnMapStepChosen && (
            <PlaceOnMapLink step={step} />
          )}
          {addressEntryStepChosen && hasRoughAddress && (
            <AddressEntryLink step={step} />
          )}
        </div>
      );

    return <PrevBackLink />;
  };

  const next = (step: StepValue) => {
    // not yet set so there is nothing after yet.
    if (problemType === "") return <div />;

    if (showAllNexts)
      return (
        <div className="next-step">
          <div
            className="fa fa-times submition-option-icon"
            onClick={() => setShowAllNexts(false)}
          />
          <div className="next-option-list">
            {!takePhotoStepChosen && doesntHavePreciseLocation && (
              <GetLocationFromPhotoLink
                className="fa fa-arrow-right wallbreathe"
                step={step}
              />
            )}
            {!coordinatesSetFromDeviceStepChosen &&
              doesntHavePreciseLocation && (
                <SetCoordinatesFromDeviceLink step={step} />
              )}
            {(newMarkerRef.current === null || !placedOnMapStepChosen) && (
              <PlaceOnMapLink step={step} />
            )}
            {(!addressEntryStepChosen || doesntHaveRoughAddress) &&
              newMarkerRef.current !== null && <AddressEntryLink step={step} />}
            {photo &&
              hasPreciseLocation &&
              hasRoughAddress &&
              addressEntryStepChosen &&
              newMarkerRef.current !== null && <UploadLink />}
            {!takePhotoStepChosen && hasPreciseLocation && (
              <GetLocationFromPhotoLink
                className="fa fa-arrow-right wallbreathe"
                step={step}
              />
            )}
            {!coordinatesSetFromDeviceStepChosen && hasPreciseLocation && (
              <SetCoordinatesFromDeviceLink step={step} />
            )}
          </div>
        </div>
      );

    return <NextForwardLink />;
  };

  // onCancel is not yet supported.  So we must use this which is equivalent to... onCancel={()=>setLoadingPhoto(false)}
  useEffect(() => {
    if (!cameraInputRef.current) return () => {};

    cameraInputRef.current.addEventListener("cancel", () =>
      setLoadingPhoto(false),
    );

    const input = cameraInputRef.current;

    return () => {
      if (!input) return;
      input.removeEventListener("cancel", console.log);
    };
  });

  return (
    <form id="problemForm" action="dialog">
      <input
        style={{ display: "none" }}
        ref={cameraInputRef}
        type="file"
        formAction="dialog"
        accept="image/*"
        capture="environment"
        onChange={handlePhotoInputChange}
      />

      <h1>{_t("problem.report-a-problem")}</h1>

      {step === StepValue.Start && (
        <div className="wizardBox">
          {!((showAllNexts || showAllPrevs) && problemType !== "") && (
            <div className={" wallbreathe"}>
              <div className={"radio-container "}>
                <input
                  type={"radio"}
                  name={"problemType"}
                  value={"pot hole"}
                  onChange={setNewProblemType}
                  checked={problemType === "pot hole"}
                />
                <span onClick={(ev) => setProblemType("pot hole")}>
                  {_t("problem.pot hole")}
                </span>
                <img
                  onClick={(ev) => setProblemType("pot hole")}
                  alt=""
                  src="./pot-hole.png"
                />
              </div>

              <div className={"radio-container"}>
                <input
                  type={"radio"}
                  name={"problemType"}
                  value={"missing sign"}
                  onChange={setNewProblemType}
                  checked={problemType === "missing sign"}
                />

                <span onClick={(ev) => setProblemType("missing sign")}>
                  {_t("problem.missing sign")}
                </span>
                <img
                  onClick={(ev) => setProblemType("missing sign")}
                  alt=""
                  src="./missing-sign.png"
                />
              </div>

              <div className={"radio-container"}>
                <input
                  type={"radio"}
                  name={"problemType"}
                  value={"damaged sign"}
                  onChange={setNewProblemType}
                  checked={problemType === "damaged sign"}
                />

                <span onClick={(ev) => setProblemType("damaged sign")}>
                  {_t("problem.damaged sign")}
                </span>
                <img
                  onClick={(ev) => setProblemType("damaged sign")}
                  alt=""
                  src="./broken-sign.png"
                />
              </div>
            </div>
          )}

          {next(StepValue.Start)}
        </div>
      )}

      {step === StepValue.LocationFromPhoto && (
        <div id="fotogroup" className="wizardBox">
          {prev(StepValue.LocationFromPhoto)}

          <div className="middle-field">
            <label className="even-dark">
              {_t("problem.photo")}
              {resizingPhoto ? (
                <span>{_t("problem.resizing-photo")}</span>
              ) : loadingPhoto ? (
                <span>{_t("problem.loading-photo")}</span>
              ) : (
                <img
                  id="problemImage"
                  className="imageUpload"
                  style={{ display: photo === defaultImage ? "none" : "block" }}
                  alt="Captured or Uploaded"
                  src={photoFormat + "," + photo}
                />
              )}
              {!loadingPhoto && (
                <button
                  tabIndex={9}
                  onClick={() => {
                    setLoadingPhoto(true);
                    cameraInputRef.current?.click();
                  }}
                >
                  {_t("problem.take-a-new-photo")}
                </button>
              )}
            </label>
          </div>

          {next(StepValue.LocationFromPhoto)}
        </div>
      )}

      {
        step === StepValue.AddressEntry && (
          <div className="wizardBox">
            {prev(StepValue.AddressEntry)}

            <div className="middle-field">
              <img
                style={{
                  maxWidth: "20vw",
                  maxHeight: "20vh",
                  display: photo === defaultImage ? "none" : "block",
                }}
                id="problemImage"
                alt="Captured or Uploaded"
                src={photoFormat + "," + photo}
              />

              <div className="wallbreathe">
                <label className="even-dark">
                  {_t("problem.city")}
                  <input
                    className="even-dark"
                    tabIndex={1}
                    type="string"
                    defaultValue={currentCity}
                    onChange={handleCityChange}
                  />
                  <span className="error">{cityError}</span>
                </label>

                <div className="  formFlow">
                  <datalist id="streets">
                    {possibleStreets.map((street) => (
                      <option value={street.tags.name} key={street.id} />
                    ))}
                  </datalist>
                  <label className={"even-dark"}>
                    {_t("problem.street name")}
                    <input
                      className="odd-dark"
                      tabIndex={streetOrder === "big-endian" ? 2 : 3}
                      type={"text"}
                      defaultValue={streetName}
                      onChange={handleStreetNameChange}
                      list="streets"
                    />

                    <span className="error">{streetNameError}</span>
                  </label>

                  <label className={"even-dark"}>
                    {_t("problem.street number")}
                    <input
                      id="problem-numero-de-calle-input"
                      tabIndex={streetOrder === "big-endian" ? 3 : 2}
                      className="odd-dark"
                      type="number"
                      defaultValue={streetNumber}
                      onChange={handleStreetNumberChange}
                    />
                  </label>
                  <span className="error">{streetNumberError}</span>
                </div>

                <label className={"odd-dark"}>
                  {_t("problem.cross-street")}
                  <input
                    className="odd-dark"
                    tabIndex={4}
                    type="text"
                    value={crossStreet}
                    onChange={handleCrossStreetChange}
                    list="streets"
                  />
                  <span className="error">{crossStreetError}</span>{" "}
                </label>

                <div className="hidden">
                  <label className="even-dark">
                    {_t("problem.latitude")}
                    <input
                      className="even-dark"
                      tabIndex={5}
                      type="string"
                      defaultValue={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </label>
                  <label className="hidden">
                    {_t("problem.longitude")}
                    <input
                      className="even-dark"
                      tabIndex={6}
                      type="string"
                      defaultValue={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </label>
                </div>

                <label className={"even-dark"} htmlFor="descriptionId">
                  {_t("problem.description")}
                  <textarea
                    className="even-dark"
                    tabIndex={7}
                    id="descriptionId"
                    onChange={handleDescriptionChange}
                    defaultValue={description}
                  />
                  <span className="error">{descriptionError}</span>
                </label>
                {debugText}
              </div>
            </div>

            {next(StepValue.AddressEntry)}
          </div>
        ) /* Address Entry */
      }

      <div className={step === StepValue.MapPlacement ? "wizardBox" : "hidden"}>
        {prev(StepValue.MapPlacement)}

        <div className="middle-field">
          <div id="map" />
        </div>

        {next(StepValue.MapPlacement)}
      </div>
    </form>
  );
}
